import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './Product.css'; // Import the CSS for styling
import concept from '../assets/images/concept_of_kengine.png'; 
import filter from '../assets/images/filter_concept.png'
import future from '../assets/images/future.png'; 

function Product() {
  const { t } = useTranslation();
  const product1Ref = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="product-page">
      <div className="product-list">
        <ul>
          <li onClick={() => scrollToSection(product1Ref)}>{t('product_km.title')}</li>
        </ul>
      </div>

      <div className="product-details">
        <section ref={product1Ref} className="product-section">
          <h2>{t('product_km.title')}</h2>
          <p>{t('product_km.description')}</p>
          <p>{t('product_km.features_intro')}</p>
          
          <h3>{t('product_km.feature1_title')}</h3>
          <p>{t('product_km.feature1_description')}</p>
          
          <img src={concept} alt="kengine concept" />
          
          <h3>{t('product_km.feature2_title')}</h3>
          <p>{t('product_km.feature2_description')}</p>
          
          <h3>{t('product_km.feature3_title')}</h3>
          <img src={filter} alt="kengine filter" />
          <p>{t('product_km.feature3_description')}</p>

          <h3>{t('product_km.application_title')}</h3>
          <img src={future} alt="kengine future support" />
          <p>{t('product_km.application_description')}</p>
        </section>
      </div>
    </div>
  );
}

export default Product;
