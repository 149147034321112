import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css'; // 可以创建一个 CSS 文件用于样式

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} lingospark. All rights reserved.</p>
        <nav className="footer-nav">
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms-of-service">Terms of Service</a>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
