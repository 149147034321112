import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Header.css'; 

function Header() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">{t('site_name')}</Link>
      </div>
      <nav className="nav-links">
        <Link to="/">{t('home_page')}</Link>
        <Link to="/product">{t('product')}</Link>
        <Link to="/use-cases">{t('use_cases')}</Link>
        <Link to="/about-us">{t('about_us.title')}</Link>
      </nav>
      <div className="language-switcher">
        <button onClick={() => changeLanguage('en')}>English</button>
        <button onClick={() => changeLanguage('zh-TW')}>中文</button>
      </div>
    </header>
  );
}

export default Header;
