import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Product from './pages/Product';
import UseCases from './pages/UseCases';
import AboutUs from './pages/AboutUs';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/product" element={<Product />} />
        <Route path="/use-cases" element={<UseCases />} />
        <Route path="/about-us" element={<AboutUs />} /> 
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
