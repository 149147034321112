import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutUs.css'; // Import styles
import founderPhoto from '../assets/images/founder.jpg';
import co_founderPhoto from '../assets/images/co-founder.jpg';

function AboutUs() {
  const { t } = useTranslation();

  return (
    <div className="about-us">
      <div className="team-section">
        <div className="founder-section">
          <img src={founderPhoto} alt="Phil Fan - Founder" className="team-photo" />
          <p>Phil Fan</p>
          <p>Founder</p>
        </div>

        <div className="cofounder-section">
          <img src={co_founderPhoto} alt="Edward Chen - Co-Founder" className="team-photo" />
          <p>Edward Chen</p>
          <p>Co-Founder</p>
        </div>
      </div>

      <div className="right-column">
        <div className="vision-section">
          <h2>{t('about_us.vision_title')}</h2>
          <p>{t('about_us.vision_description')}</p>
        </div>

        <div className="contact-section contact-info">
          <h2>{t('about_us.contact_title')}</h2>
          <p>{t('about_us.office_address')}</p>
          <p>Email: {t('about_us.email')}</p>
          <p>Phone: {t('about_us.phone')}</p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
