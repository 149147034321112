import React from 'react';
import { useTranslation } from 'react-i18next';
import './Home.css'; // 导入样式
import backgroundImage from '../assets/images/home_background.jpg'; 

function Home() {
  const { t } = useTranslation();

  return (
    <div className="home" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <h1>{t('home.title')}</h1>
      <p>{t('home.subtitle')}</p>
      <button>{t('home.try_button')}</button>
    </div>
  );
}

export default Home;
