import React, { useRef } from 'react';
import './UseCases.css'; // Import the CSS for styling
import problem from '../assets/images/problem.png'; 

function UseCases() {
  const case1Ref = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="use-cases-page">
      <div className="case-list">
        <ul>
          <li onClick={() => scrollToSection(case1Ref)}>線上教育 - 實際案例</li>
          {/* 可以在這裡增加更多應用案例 */}
        </ul>
      </div>
  
      <div className="case-details">
        <section ref={case1Ref} className="case-section">
          <h2>線上教育 - 實際案例</h2>
  
          <h3>客戶挑戰</h3>
          <p>我們的一位客戶是一家提供國中、高中線上課程的教育科技公司。他們擁有大量的教學影片和講義，但學生經常難以快速找到特定課題的相關內容，這對學習效果造成了不小的挑戰。</p>
          <img src={problem} alt="customer problem" />
  
          <h3>我們的解決方案</h3>
          <p>針對這一挑戰，我們為該客戶部署了 <strong>kengine 知識搜尋引擎</strong>。kengine 能夠自動解析並學習客戶提供的大量影片和講義資料，建立起專屬的知識庫。這一過程不需要客戶進行繁瑣的手動編輯和標籤處理，極大地節省了時間和人力成本。</p>
          <p>通過 kengine，我們為該公司自動建立了一個專門針對教育內容的 AI 助理。這個助理能夠即時回應學生的問題，並引導他們到相關的影片片段或講義內容，從而顯著提高學習效率。</p>
  
          <h3>使用體驗與效益</h3>
          <p>學生使用 kengine 時，無需記住特定的關鍵字，只需使用自然語言描述問題即可。例如：</p>
          <ul>
            <li>「從A點移動到B點具有方向性，是什麼？」</li>
            <li>「直角三角形的斜邊如何計算？」</li>
            <li>「最簡根式是什麼，怎麼變成最簡根式？」</li>
          </ul>
          <p>kengine 會快速解析這些問題，並從知識庫中找出最相關的答案，精確地引導學生到具體的影片片段或講義段落。</p>
          <p>此解決方案不僅大大提升了學生的學習效率，還顯著改善了他們的學習體驗，減少了學生在學習過程中感到的困惑和挫折。</p>
  
          <h3>成果與回饋</h3>
          <p>自從 kengine 上線以來，該教育科技公司獲得了以下成果：</p>
          <ol>
            <li>線上影片不再需要人工標註標籤。</li>
            <li>系統在一晚內自動生成超過 50 部影片的字幕和專屬知識庫。</li>
            <li>即使學生只記住了一部分資訊，他們也能迅速找到想要的答案，學習變得更加輕鬆有效。</li>
          </ol>
          <p>透過 kengine，該公司鞏固了他們在教育科技市場中的領先地位，並進一步提升了其市場競爭力。</p>
        </section>
      </div>
    </div>
  );
  
  
}

export default UseCases;
